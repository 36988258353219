import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Heading,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Alert,
  AlertIcon,
  Container,
  Center,
} from '@chakra-ui/react';

const CriarUsuario = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roleId, setRoleId] = useState('');
  const [error, setError] = useState('');

  const roles = [
    { id: 1, role: 'ADMIN', is_single_user_role: true },
    { id: 2, role: 'CEO', is_single_user_role: true },
    { id: 3, role: 'CHO', is_single_user_role: true },
    { id: 4, role: 'CMO', is_single_user_role: true },
    { id: 5, role: 'CTO', is_single_user_role: true },
    { id: 6, role: 'GAME SHARK', is_single_user_role: true },
    { id: 7, role: 'Gerente de Projeto', is_single_user_role: true },
    { id: 8, role: 'Representante 2', is_single_user_role: false },
    { id: 9, role: 'Representante 3', is_single_user_role: false },
    { id: 10, role: 'Representante 1', is_single_user_role: false },
  ];

  const handleCreateUser = async () => {
    setError('');
    try {
      await axios.post('https://membros.tanayoupop.com.br/src/backend/Controllers/creat.php', {
        name,
        email,
        password,
        role_id: roleId,
      });
      alert('Usuário criado com sucesso');
      setName('');
      setEmail('');
      setPassword('');
      setRoleId('');
    } catch (error) {
      console.error('Error creating user', error);
      setError('Erro ao criar o usuário. Por favor, tente novamente.');
    }
  };

  return (
    <Box bg="gray.100" minHeight="100vh" py={10}>
      <Center>
        <Container maxW="lg" bg="white" p={10} borderRadius="md" boxShadow="md">
          <Heading mb={5} textAlign="center">Criar Usuário</Heading>
          {error && (
            <Alert status="error" mb={5}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          <VStack spacing={4} align="stretch">
            <FormControl id="name" isRequired>
              <FormLabel>Nome</FormLabel>
              <Input
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Senha</FormLabel>
              <Input
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="role" isRequired>
              <FormLabel>Cargo</FormLabel>
              <Select
                placeholder="Selecione o Cargo"
                value={roleId}
                onChange={(e) => setRoleId(e.target.value)}
              >
                {roles.map((role) => (
                  <option
                    key={role.id}
                    value={role.id}
                    disabled={role.is_single_user_role && role.user_exists}
                  >
                    {role.role} {role.is_single_user_role && role.user_exists ? '(Já ocupado)' : ''}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleCreateUser} width="full">
              Criar Usuário
            </Button>
          </VStack>
        </Container>
      </Center>
    </Box>
  );
};

export default CriarUsuario;
