import React from 'react';

function FinancialStatement() {
  return (
    <div>
      <h1>Financial Statement</h1>
      {/* Visualizações de extrato financeiro */}
      <p>View your financial statement here.</p>
    </div>
  );
}

export default FinancialStatement;
