import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  Container,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const ListarUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    setError('');
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/userlistcontroller.php');
      setUsuarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários', error);
      setError('Erro ao buscar usuários. Por favor, tente novamente.');
    }
  };

  const confirmDeleteUser = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleDeleteUser = async () => {
    setError('');
    try {
      await axios.delete(`https://membros.tanayoupop.com.br/src/backend/Controllers/userlistcontroller.php?id=${selectedUser.id}`);
      fetchUsuarios();
      onClose();
    } catch (error) {
      console.error('Erro ao deletar usuário', error);
      setError('Erro ao deletar usuário. Por favor, tente novamente.');
    }
  };

  return (
    <Box bg="gray.100" minHeight="100vh" py={10}>
      <Center>
        <Container maxW="7xl" bg="white" p={10} borderRadius="md" boxShadow="md">
          <Heading mb={5} textAlign="center">Lista de Usuários</Heading>
          {error && (
            <Alert status="error" mb={5}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          <Box overflowY="auto" maxHeight="600px">
            <Table variant="simple" size="lg">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Email</Th>
                  <Th>Cargo</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {usuarios.map((usuario) => (
                  <Tr key={usuario.id}>
                    <Td>{usuario.name}</Td>
                    <Td>{usuario.email}</Td>
                    <Td>{usuario.role}</Td>
                    <Td>
                      <Button colorScheme="red" onClick={() => confirmDeleteUser(usuario)}>
                        Deletar
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Container>
      </Center>

      {/* Modal de confirmação */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Exclusão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Tem certeza de que deseja excluir o usuário {selectedUser?.name}?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteUser}>
              Deletar
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ListarUsuarios;
