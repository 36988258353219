import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Heading,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Checkbox,
  CheckboxGroup,
  Stack,
  FormControl,
  FormLabel,
  HStack,
} from '@chakra-ui/react';
import Tree from 'react-d3-tree';

const defaultAvatar = 'https://via.placeholder.com/150';

const GroupManagement = () => {
  const [groups, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [gerenteVendas2Id, setGerenteVendas2Id] = useState('');
  const [gerentesVendas2, setGerentesVendas2] = useState([]);
  const [gerentesVendas1, setGerentesVendas1] = useState([]);
  const [selectedGerentesVendas1, setSelectedGerentesVendas1] = useState([]);
  const [allRepresentantes, setAllRepresentantes] = useState([]);
  const [editGroup, setEditGroup] = useState(null);
  const [representantesByGerente, setRepresentantesByGerente] = useState({});
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  useEffect(() => {
    fetchGroups();
    fetchGerentesVendas2();
    fetchGerentesVendas1();
    fetchRepresentantes();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php');
      console.log('Groups data:', response.data); // Log de depuração
      setGroups(response.data || []);
    } catch (error) {
      console.error('Error fetching groups', error);
    }
  };

  const fetchGerentesVendas2 = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php?role=gerentesVendas2');
      console.log('Gerentes de Vendas 2 data:', response.data); // Log de depuração
      setGerentesVendas2(response.data || []);
    } catch (error) {
      console.error('Error fetching Gerentes de Vendas 2', error);
    }
  };

  const fetchGerentesVendas1 = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php?role=gerentesVendas1');
      console.log('Representante 2 data:', response.data); // Log de depuração
      setGerentesVendas1(response.data || []);
    } catch (error) {
      console.error('Error fetching Representante 2', error);
    }
  };

  const fetchRepresentantes = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php?role=representantes');
      console.log('Representantes data:', response.data); // Log de depuração
      setAllRepresentantes(response.data || []);
    } catch (error) {
      console.error('Error fetching Representantes', error);
    }
  };

  const handleCreateGroup = async () => {
    try {
      const gerentes_vendas_1 = selectedGerentesVendas1.map((gerenteId) => ({
        id: gerenteId,
        gerente_vendas_2_id: gerenteVendas2Id,
        representantes: representantesByGerente[gerenteId] || [],
      }));

      await axios.post('https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php', {
        name: groupName,
        gerente_vendas_2_id: gerenteVendas2Id,
        gerentes_vendas_1,
      });
      fetchGroups();
      onCreateModalClose();
    } catch (error) {
      console.error('Error creating group', error);
    }
  };

  const handleEditGroup = async () => {
    try {
      const gerentes_vendas_1 = selectedGerentesVendas1.map((gerenteId) => ({
        id: gerenteId,
        gerente_vendas_2_id: gerenteVendas2Id,
        representantes: representantesByGerente[gerenteId] || [],
      }));

      await axios.put(`https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php?id=${editGroup.id}`, {
        name: groupName,
        gerente_vendas_2_id: gerenteVendas2Id,
        gerentes_vendas_1,
      });
      fetchGroups();
      setEditGroup(null);
      onEditModalClose();
    } catch (error) {
      console.error('Error editing group', error);
    }
  };

  const handleGerenteVendas1Change = (value) => {
    setSelectedGerentesVendas1(value);
  };

  const handleRepresentantesChange = (gerenteId, values) => {
    setRepresentantesByGerente((prev) => ({
      ...prev,
      [gerenteId]: values,
    }));
  };

  const openCreateGroupModal = () => {
    setGroupName('');
    setGerenteVendas2Id('');
    setSelectedGerentesVendas1([]);
    setRepresentantesByGerente({});
    onCreateModalOpen();
  };

  const openEditGroupModal = async (group) => {
    try {
      const response = await axios.get(`https://membros.tanayoupop.com.br/src/backend/Controllers/GroupController.php?id=${group.id}`);
      const groupData = response.data;

      setEditGroup(groupData);
      setGroupName(groupData.name);
      setGerenteVendas2Id(groupData.parent_id);
      const gerentes1 = groupData.subordinates.filter((sub) => sub.role_id === 8);
      setSelectedGerentesVendas1(gerentes1.map((g) => g.id));
      const representantes = {};
      gerentes1.forEach((g) => {
        representantes[g.id] = g.subordinates ? g.subordinates.map((rep) => rep.id) : [];
      });
      setRepresentantesByGerente(representantes);
      onEditModalOpen();
    } catch (error) {
      console.error('Error fetching group details', error);
    }
  };

  const generateTreeData = () => {
    if (!Array.isArray(groups) || !groups.length) return [{ name: 'Grupos', children: [] }];
    const treeData = groups.map((group) => {
      const gerenteVendas2 = gerentesVendas2.find((gerente) => gerente.id === group.parent_id) || {};
      return {
        name: gerenteVendas2.name,
        attributes: {
          Cargo: 'Representante 3',
          Grupo: group.name,
        },
        children: (group.subordinates || []).map((sub) => ({
          name: sub.name,
          attributes: {
            Cargo: 'Representante 2',
          },
          children: (sub.subordinates || []).map((rep) => ({
            name: rep.name,
            attributes: {
              Cargo: 'Representante 1',
            },
          })),
          nodeSvgShape: {
            shape: 'rect',
            shapeProps: {
              width: 180,
              height: 100,
              x: -90,
              y: -50,
              fill: '#90EE90',
              stroke: '#4CAF50',
            }
          },
        })),
        nodeSvgShape: {
          shape: 'rect',
          shapeProps: {
            width: 200,
            height: 100,
            x: -100,
            y: -50,
            fill: '#ADD8E6',
            stroke: '#1E90FF',
          }
        },
      };
    });
    return [{ name: 'Grupos', children: treeData }];
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Gerenciamento de Grupos</Heading>
      <HStack spacing={4} mb={5}>
        <Button onClick={openCreateGroupModal} colorScheme="blue">Criar Grupo</Button>
        <a href="/group-edit" style={{ 
        background: '#319795', 
        width: '100%', 
        textAlign: 'center', 
        display: 'flex', 
        height: '40px', 
        justifyContent: 'center', 
        borderRadius: '5px', 
        fontWeight: 700, 
        color: 'white', 
        alignContent: 'center', 
        flexDirection: 'column' 
      }}>Editar Grupos</a>
      </HStack>

      <Box height="800px">
      <Tree 
          data={generateTreeData()} 
          separation={{ siblings: 1, nonSiblings: 1.5 }}
          nodeSize={{ x: 280, y: 200 }}  // Ajuste a distância aqui
          translate={{ x: 0, y: 200 }}  // Ajuste a posição inicial da árvore
        />
      </Box>

      {/* Modal de Criação */}
      <Modal isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar Grupo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Nome do Grupo</FormLabel>
              <Input
                placeholder="Nome do Grupo"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Selecione o Representante 3</FormLabel>
              <Select placeholder="Selecione o Representante 3" value={gerenteVendas2Id} onChange={(e) => setGerenteVendas2Id(e.target.value)}>
                {gerentesVendas2.map((gerente) => (
                  <option key={gerente.id} value={gerente.id}>
                    {gerente.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Representante 2</FormLabel>
              <CheckboxGroup value={selectedGerentesVendas1} onChange={handleGerenteVendas1Change}>
                <Stack spacing={2}>
                  {gerentesVendas1.map((gerente) => (
                    <Checkbox key={gerente.id} value={gerente.id}>
                      {gerente.name}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
            {selectedGerentesVendas1.map((gerenteId) => (
              <Box key={gerenteId} mt={4}>
                <FormControl>
                  <FormLabel>Representantes do {gerentesVendas1.find((gerente) => gerente.id === gerenteId)?.name}</FormLabel>
                  <CheckboxGroup
                    value={representantesByGerente[gerenteId] || []}
                    onChange={(values) => handleRepresentantesChange(gerenteId, values)}
                  >
                    <Stack spacing={2}>
                      {allRepresentantes.map((representante) => (
                        <Checkbox
                          key={representante.id}
                          value={representante.id}
                          isDisabled={Object.values(representantesByGerente).flat().includes(representante.id) && !(representantesByGerente[gerenteId]?.includes(representante.id))}
                        >
                          {representante.name}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateGroup}>
              Criar
            </Button>
            <Button variant="ghost" onClick={onCreateModalClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de Edição */}
      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Grupos</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="500px" overflowY="auto">
            <VStack spacing={4} align="stretch">
              {Array.isArray(groups) && groups.map((group) => (
                <Box key={group.id} p={5} shadow="md" borderWidth="1px">
                  <Heading fontSize="xl">{group.name}</Heading>
                  <Button mt={2} onClick={() => { openEditGroupModal(group); onEditModalClose(); }}>Editar</Button>
                </Box>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal de Edição de um Grupo */}
      <Modal isOpen={editGroup !== null} onClose={() => setEditGroup(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Grupo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Nome do Grupo</FormLabel>
              <Input
                placeholder="Nome do Grupo"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Selecione o Representante 3</FormLabel>
              <Select placeholder="Selecione o Representante 3" value={gerenteVendas2Id} onChange={(e) => setGerenteVendas2Id(e.target.value)}>
                {gerentesVendas2.map((gerente) => (
                  <option key={gerente.id} value={gerente.id}>
                    {gerente.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Representante 2</FormLabel>
              <CheckboxGroup value={selectedGerentesVendas1} onChange={handleGerenteVendas1Change}>
                <Stack spacing={2}>
                  {gerentesVendas1.map((gerente) => (
                    <Checkbox key={gerente.id} value={gerente.id}>
                      {gerente.name}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
            {selectedGerentesVendas1.map((gerenteId) => (
              <Box key={gerenteId} mt={4}>
                <FormControl>
                  <FormLabel>Representantes do {gerentesVendas1.find((gerente) => gerente.id === gerenteId)?.name}</FormLabel>
                  <CheckboxGroup
                    value={representantesByGerente[gerenteId] || []}
                    onChange={(values) => handleRepresentantesChange(gerenteId, values)}
                  >
                    <Stack spacing={2}>
                      {allRepresentantes.map((representante) => (
                        <Checkbox
                          key={representante.id}
                          value={representante.id}
                          isDisabled={Object.values(representantesByGerente).flat().includes(representante.id) && !(representantesByGerente[gerenteId]?.includes(representante.id))}
                        >
                          {representante.name}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEditGroup}>
              Salvar Alterações
            </Button>
            <Button variant="ghost" onClick={() => setEditGroup(null)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GroupManagement;
