import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Heading,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Checkbox,
  CheckboxGroup,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Text,
} from '@chakra-ui/react';

const EditarGrupo = () => {
  const [groups, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [gerenteVendas2Id, setGerenteVendas2Id] = useState('');
  const [gerentesVendas2, setGerentesVendas2] = useState([]);
  const [gerentesVendas1, setGerentesVendas1] = useState([]);
  const [selectedGerentesVendas1, setSelectedGerentesVendas1] = useState([]);
  const [allRepresentantes, setAllRepresentantes] = useState([]);
  const [representantesByGerente, setRepresentantesByGerente] = useState({});
  const [editGroup, setEditGroup] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchGroups();
    fetchGerentesVendas2();
    fetchGerentesVendas1();
    fetchRepresentantes();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/index.php');
      setGroups(response.data || []);
    } catch (error) {
      console.error('Error fetching groups', error);
    }
  };

  const fetchGerentesVendas2 = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?role=gerentesVendas2');
      setGerentesVendas2(response.data || []);
    } catch (error) {
      console.error('Error fetching Gerentes de Vendas 2', error);
    }
  };

  const fetchGerentesVendas1 = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?role=gerentesVendas1');
      setGerentesVendas1(response.data || []);
    } catch (error) {
      console.error('Error fetching Representante 2', error);
    }
  };

  const fetchRepresentantes = async () => {
    try {
      const response = await axios.get('https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?role=representantes');
      setAllRepresentantes(response.data || []);
    } catch (error) {
      console.error('Error fetching Representantes', error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const gerentes_vendas_1 = selectedGerentesVendas1.map((gerenteId) => ({
        id: gerenteId,
        gerente_vendas_2_id: gerenteVendas2Id,
        representantes: representantesByGerente[gerenteId] || [],
      }));

      const removedGerentesVendas1 = gerentesVendas1.filter((gerente) => !selectedGerentesVendas1.includes(gerente.id));

      await axios.put(`https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?id=${editGroup.id}`, {
        name: groupName,
        gerente_vendas_2_id: gerenteVendas2Id,
        gerentes_vendas_1,
        removed_gerentes_vendas_1: removedGerentesVendas1,
      });
      fetchGroups();
      setEditGroup(null);
      onClose();
    } catch (error) {
      console.error('Error saving changes', error);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      await axios.delete(`https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?id=${editGroup.id}`);
      fetchGroups();
      setEditGroup(null);
      onClose();
    } catch (error) {
      console.error('Error deleting group', error);
    }
  };

  const handleGerenteVendas1Change = (value) => {
    setSelectedGerentesVendas1(value);
  };

  const handleRepresentantesChange = (gerenteId, values) => {
    setRepresentantesByGerente((prev) => ({
      ...prev,
      [gerenteId]: values,
    }));
  };

  const openEditGroupModal = async (group) => {
    try {
      const response = await axios.get(`https://membros.tanayoupop.com.br/src/backend/Controllers/index.php?id=${group.id}`);
      const groupData = response.data;

      setEditGroup(groupData);
      setGroupName(groupData.name);
      setGerenteVendas2Id(groupData.parent_id);
      const gerentes1 = groupData.subordinates.filter((sub) => sub.role_id === 8);
      setSelectedGerentesVendas1(gerentes1.map((g) => g.id));
      const representantes = {};
      gerentes1.forEach((g) => {
        representantes[g.id] = g.subordinates ? g.subordinates.map((rep) => rep.id) : [];
      });
      setRepresentantesByGerente(representantes);
      onOpen();
    } catch (error) {
      console.error('Error fetching group details', error);
    }
  };

  const renderGerentesVendas1Options = () => {
    return gerentesVendas1.map((gerente) => (
      <Checkbox
        key={gerente.id}
        value={gerente.id}
        isChecked={selectedGerentesVendas1.includes(gerente.id)}
        onChange={(e) => handleGerenteVendas1Change(
          e.target.checked
            ? [...selectedGerentesVendas1, gerente.id]
            : selectedGerentesVendas1.filter((id) => id !== gerente.id)
        )}
      >
        <Text color={gerente.parent_id === gerenteVendas2Id ? "green" : (gerente.parent_id ? "purple" : "black")}>
          {gerente.name}
        </Text>
      </Checkbox>
    ));
  };

  const renderRepresentantesOptions = (gerenteId) => {
    return allRepresentantes.map((representante) => (
      <Checkbox
        key={representante.id}
        value={representante.id}
        isChecked={representantesByGerente[gerenteId]?.includes(representante.id)}
        onChange={(e) => handleRepresentantesChange(
          gerenteId,
          e.target.checked
            ? [...(representantesByGerente[gerenteId] || []), representante.id]
            : (representantesByGerente[gerenteId] || []).filter((id) => id !== representante.id)
        )}
        colorScheme="gray"
      >
        <Text color={representante.parent_id === gerenteId ? "green" : (representante.parent_id ? "purple" : "black")}>
          {representante.name}
        </Text>
      </Checkbox>
    ));
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Gerenciamento de Grupos</Heading>
      <VStack spacing={9} mb={9}>
        {groups.map((group) => (
          <Box key={group.id} p={9} shadow="md" borderWidth="1px">
            <Heading fontSize="xl">{group.name}</Heading>
            <Button mt={2} onClick={() => openEditGroupModal(group)}>Editar</Button>
          </Box>
        ))}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Grupo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Nome do Grupo</FormLabel>
              <Input
                placeholder="Nome do Grupo"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Selecione o Representante 3</FormLabel>
              <Select value={gerenteVendas2Id} isDisabled>
                {gerentesVendas2.map((gerente) => (
                  <option key={gerente.id} value={gerente.id}>
                    {gerente.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Representante 2</FormLabel>
              <CheckboxGroup value={selectedGerentesVendas1} onChange={handleGerenteVendas1Change}>
                <Stack spacing={2}>
                  {renderGerentesVendas1Options()}
                </Stack>
              </CheckboxGroup>
            </FormControl>
            {selectedGerentesVendas1.map((gerenteId) => (
              <Box key={gerenteId} mt={4}>
                <FormControl>
                  <FormLabel>Representantes do {gerentesVendas1.find((gerente) => gerente.id === gerenteId)?.name}</FormLabel>
                  <CheckboxGroup
                    value={representantesByGerente[gerenteId] || []}
                    onChange={(values) => handleRepresentantesChange(gerenteId, values)}
                  >
                    <Stack spacing={2}>
                      {renderRepresentantesOptions(gerenteId)}
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteGroup}>
              Excluir Grupo
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleSaveChanges}>
              Salvar Alterações
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditarGrupo;
