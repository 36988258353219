import React, { useState, useEffect } from 'react';
import { Box, Button, useToast, Text, Center, VStack, Image, Flex, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const MercadoPagoAuthLink = () => {
    const [hasCollectorId, setHasCollectorId] = useState(false);
    const userId = localStorage.getItem('userId');
    const location = useLocation();
    const toast = useToast();

    useEffect(() => {
        const checkCollectorId = async () => {
            const response = await fetch(`https://membros.tanayoupop.com.br/src/backend/Controllers/check_collector_id.php?userId=${userId}`);
            const data = await response.json();
            setHasCollectorId(data.hasCollectorId);
        };

        checkCollectorId();

        // Check URL parameters for success messages
        const queryParams = new URLSearchParams(location.search);
        const success = queryParams.get('success');
        const message = queryParams.get('message');

        if (message) {
            toast({
                title: success === 'true' ? 'Sucesso' : 'Erro',
                description: decodeURIComponent(message),
                status: success === 'true' ? 'success' : 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
            });
        }
    }, [location.search]); // Depend on location.search to re-run on URL change

    return (
        <Flex direction="column" align="center" justify="center" h="100vh" bgGradient="linear(209.9deg, #068ee4 24.42%, #03117f 115.13%)">
            <Box className="boltz" align="center" justify="center" textAlign="center" mb={10}>
                <Image src="https://logodownload.org/wp-content/uploads/2019/06/mercado-pago-logo.png" className="bolt" alt="Mercado Pago Logo" />
                <Heading as="h2" size="lg" fontWeight="bold" color="white" mt={4}>
                    Gerencie sua conta MercadoPago para receber sua comissão
                </Heading>
            </Box>
            <VStack spacing={4} p={8} boxShadow="md" bg="white" borderRadius="lg">
                {hasCollectorId ? (
                    <Text fontSize="lg" color="purple.500">
                        Sua conta já está conectada ao Mercado Pago.
                    </Text>
                ) : (
                    <Button as="a" href={`https://membros.tanayoupop.com.br/src/backend/Controllers/MercadoPagoAuthController.php?userId=${userId}`} colorScheme="purple">
                        Conectar com Mercado Pago
                    </Button>
                )}
            </VStack>
        </Flex>
    );
};

export default MercadoPagoAuthLink;
